import {Stack, Typography} from '@mui/material';
import Image from 'next/image';


function LoadingAdian(props) {
  const {title, subtitle} = props;
  return (
    <Stack width="100%" alignItems="center" marginTop={4}>
      <Stack marginBottom={-4} alignItems="center">
        <Typography variant="body2">{title}</Typography>
        <Typography variant="body2" color="gray">
          {subtitle}
        </Typography>
      </Stack>
      <Image src="/svg/loading.svg" alt="loading" width={100} height={100} />
    </Stack>
  );
}

export default LoadingAdian;
