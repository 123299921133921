import {Stack} from '@mui/material';
import {LoadingAdian} from '../LoadingAdian';

export function Loading() {
  return (
    <Stack
      width="100%"
      heigth="100%"
      alignItems="center"
      justifyContent="center"
    >
      <LoadingAdian title="carregando..." />;
    </Stack>
  );
}
